<script>
export default {
  name: 'LauncherWidgetComponent',
  components: {},
};
</script>
<template>
  <div class="launcher__container">
    <div class="launcher-widget__container">
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/oculus.png"
        alt="Очки виртуальной реальности Oculus"
        class="vr-element vr-1"
      />
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/left-joystick.png"
        alt="Левый джойстик виртуальных очков"
        class="vr-element vr-2"
      />
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/right-joystick.png"
        alt="Правый джойстик виртуальных очков"
        class="vr-element vr-3"
      />
      <!-- <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/pico.png"
        alt="Очки виртуальной реальности Pico"
        class="vr-element vr-4"
      />
      <img
        src="..//..//..//assets/images/Launcher-background/launcher-widget-background/vive.png"
        alt="Очки виртуальной реальности Vive"
        class="vr-element vr-5"
      /> -->
      <div class="info-title">
        Откройте для себя<br />экосистему<br />
        DIVE
      </div>
      <div class="link-block">
        <p class="link-label">
          Познакомиться с тренажерами и начать обучение можно уже сейчас
        </p>
        <div class="link-button">
          <router-link
            :to="{
              name: 'LauncherView',
            }"
            class="btn btn-full"
            >Скачать лаунчер
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.launcher__container {
  padding: 20px 0;
}

.launcher-widget__container {
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(10, 114px);
  grid-template-rows: repeat(6, 114px);
  margin: 0 104px;
  max-width: 1140px;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.launcher-widget__container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  align-self: center;
  justify-self: center;
}

// .launcher-widget__container {
//   position: relative;
//   display: flex;
//   width: 100%;
//   min-height: 100%;
//   overflow: hidden;
// }

// .vr-element {
//   position: absolute;
// }

.vr-1 {
  grid-column: 1 / 6;
  grid-row: 1 / 5;
}

.vr-2 {
  grid-column: 6 / 9;
  grid-row: 2 / 4;
}

.vr-3 {
  grid-column: 9 / 11;
  grid-row: 3 / 5;
}

// .info__container {
//   grid-column: 3 / 9;
//   grid-row: 4 / 6;
//   display: grid;
//   grid-template-columns: 2fr 1fr 1fr 1fr;
//   grid-auto-rows: auto;
//   width: 100%;
//   max-width: 1140px;
//   margin-top: auto;
//   margin-left: auto;
//   margin-right: auto;
// }

.info-title {
  grid-column: 1 / 8;
  grid-row: 4 / 7;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 900;
  font-size: 84px;
  line-height: 100%;
  letter-spacing: 3px;
  align-self: center;
  color: #222e37;
}

.link-block {
  grid-column: 7 / 11;
  grid-row: 5 / 7;
}

.link-label {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  line-height: 120%;
  font-size: 22px;
  color: #222e37b3;
  margin-bottom: 50px;
  margin-top: 40px;
}

.link-button {
}
</style>
