<template>
  <div class="contact-us">
    <div class="container contact-us__container">
      <div class="title-row">
        <section-title
          :subtitle="$t('about-us.subtitle')"
          :title="$t('about-us.title')"
        />
        <selectable-list
          v-model:value="activeTab"
          :items="[
            {
              value: 'aboutUs',
              label: $t('about-us.about-us.title'),
              key: 'aboutUs',
            },
            {
              value: 'services',
              label: $t('about-us.services.title'),
              key: 'services',
            },
          ]"
        />
      </div>

      <div v-if="activeTab === 'aboutUs'" class="description-column">
        <h3>
          {{ $t('about-us.about-us.description.title') }}
        </h3>
        <p>
          {{ $t('about-us.about-us.description.text1') }}
          <br />
          <br />
          {{ $t('about-us.about-us.description.text2') }}
          <br />
          <br />
          {{ $t('about-us.about-us.description.text3') }}
        </p>
      </div>
      <div v-else class="card-row">
        <CollapseTextCard
          :description="$t('about-us.services.cards.1.description')"
          :title="$t('about-us.services.cards.1.title')"
          :index="1"
        />
        <CollapseTextCard
          :description="$t('about-us.services.cards.2.description')"
          :title="$t('about-us.services.cards.2.title')"
          :index="2"
        />
        <CollapseTextCard
          :description="$t('about-us.services.cards.3.description')"
          :title="$t('about-us.services.cards.3.title')"
          :index="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/shared/ui/SectionTitle.vue';
import CollapseTextCard from '@/shared/ui/CollapseTextCard.vue';
import SelectableList from '@/shared/ui/SelectableList.vue';

export default {
  name: 'AboutUsComponent',
  components: { SelectableList, CollapseTextCard, SectionTitle },
  data() {
    return {
      activeTab: 'aboutUs',
    };
  },
  methods: {
    onSelect(value) {
      this.activeTab = value;
    },
  },
};
</script>

<style scoped lang="scss">
.contact-us {
  padding: 60px 0;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-top: 20px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    @media screen and (max-width: 768px) {
      .contact-us__container {
        padding: 10px;
      }
    }
  }

  .title-row {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media screen and (max-width: 1200px) {
      padding: 10px;
    }

    @media screen and (max-width: 768px) {
      padding: 10px;
    }
  }

  .card-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    @media screen and (max-width: 768px) {
      padding: 5px;
    }
  }

  .description-column {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 10px;

    h3 {
      color: #222e37;
      font-family: Raleway;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 36px */
    }
    p {
      color: rgba(34, 46, 55, 0.7);
      font-family: Raleway;
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }

    @media screen and (max-width: 768px) {
      padding: 10px;
    }
  }
}
</style>
