<template>
  <div class="footer-menu">
    <div class="container">
      <div class="footer-menu__logo">
        <img src="../../../../assets/images/bottom-logo.png" />
      </div>
      <nav class="footer-menu__navigation">
        <div class="navigation__group">
          <h5 class="group__title">{{ $t('sitemap') }}</h5>
          <router-link
            :to="{
              name: 'SimulatorsView',
              params: { locale: $route.params.locale },
            }"
            class="footer__link"
            >{{ $t('menu.trainers') }}</router-link
          >
          <router-link
            :to="{
              name: 'ServicesView',
              params: { locale: $route.params.locale },
            }"
            class="footer__link"
            >{{ $t('menu.services') }}</router-link
          >
          <router-link
            :to="{
              name: 'MethodistsView',
              params: { locale: $route.params.locale },
            }"
            class="footer__link"
            >{{ $t('menu.methodists') }}</router-link
          >
          <router-link
            :to="{
              name: 'ContactsView',
              params: { locale: $route.params.locale },
            }"
            class="footer__link"
            >{{ $t('menu.contacts') }}</router-link
          >
          <a href="mailto:dive@tpu.ru" class="footer__link">{{
            $t('menu.4developers')
          }}</a>
        </div>
        <div class="navigation__group">
          <h5 class="group__title">{{ $t('category') }}</h5>
          <router-link
            v-for="tag in tags"
            :key="tag.title"
            :to="{
              name: 'SimulatorsView',
              params: { locale: $route.params.locale },
            }"
            class="footer__link"
            @click="set_category(tag.id)"
          >
            {{ tag.title }}
          </router-link>
        </div>
        <div class="navigation__group">
          <h5 class="group__title">{{ $t('menu.services') }}</h5>
          <a href="#" class="footer__link">{{ $t('service1') }}</a>
          <a href="#" class="footer__link">{{ $t('service2') }}</a>
          <a href="#" class="footer__link">{{ $t('service3') }}</a>
        </div>
        <div class="navigation__group">
          <h5 class="group__title">{{ $t('we-socials') }}</h5>
          <SocialNetworks />
        </div>
      </nav>

      <ScrollToTopBtn />
    </div>
  </div>
</template>

<script>
import SocialNetworks from '@/shared/ui/SocialNetworks.vue';
import { mapActions, mapState } from 'pinia';
import { useTagsStore } from '@/app/store/tagsStore';
import { useApplicationsStore } from '@/app/store/applicationsStore';
import ScrollToTopBtn from '@/shared/ui/ScrollToTopBtn.vue';

export default {
  name: 'FooterComponent',
  components: { ScrollToTopBtn, SocialNetworks },
  computed: {
    ...mapState(useTagsStore, ['tags', 'loading']),
  },
  mounted() {
    this.getTags();
  },
  methods: {
    ...mapActions(useTagsStore, ['getTags']),
    ...mapActions(useApplicationsStore, ['setCategory']),
    set_category(id) {
      this.setCategory(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-menu {
  font-family: 'Raleway', sans-serif;
  background: #181818;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding-top: 60px;
  padding-bottom: 50px;
  .container {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__logo {
    display: flex;
    flex-direction: column;
    width: 57px;
    height: 57px;
    @media screen and (max-width: 700px) {
      width: 157px;
      height: 157px;
      margin-bottom: 5em;
    }
  }
  &__navigation {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding-left: 90px;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 1024px) {
      padding-left: 30px;
    }
    @media screen and (max-width: 700px) {
      padding-left: 0;
      flex-direction: column;
      align-items: center;
    }
    .navigation__group {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      @media screen and (max-width: 1024px) {
        width: 22%;
        word-break: break-word;
      }
      @media screen and (max-width: 700px) {
        width: 80%;
        margin: auto;
        padding-top: 2em;
        padding-bottom: 3em;
        align-items: center;
      }
      .group__title {
        color: #ffffff;
        letter-spacing: -0.1px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin: 0;
      }
      .footer__link {
        margin-top: 20px;
      }
    }
  }
}
</style>
