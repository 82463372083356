<template>
  <div
    class="language-button-wrapper"
    :class="[isTransparent ? 'white' : 'black']"
  >
    <router-link
      :to="{ name: $route.params.name, params: { locale: 'ru', id: 21 } }"
      class="language-button__item"
      :class="[isRuActive ? 'active' : '']"
      >{{ $t('language_ru') }}</router-link
    >
    <span class="divider">&nbsp;/&nbsp;</span>
    <router-link
      :to="{ name: $route.params.name, params: { locale: 'en', id: 21 } }"
      class="language-button__item"
      :class="[EnActive ? 'active' : '']"
      >{{ $t('language_en') }}</router-link
    >
    <span class="divider">&nbsp;/&nbsp;</span>
    <router-link
      :to="{ name: $route.params.name, params: { locale: 'zh', id: 21 } }"
      class="language-button__item"
      :class="[ZhActive ? 'active' : '']"
      >{{ $t('language_zh') }}</router-link
    >
  </div>
</template>

<script>
export default {
  name: 'LanguageChoiceComponent',
  props: {
    isTransparent: Boolean,
    active: Boolean,
  },
  computed: {
    isRuActive() {
      return this.$route.params.locale === 'ru';
    },
    EnActive() {
      return this.$route.params.locale === 'en';
    },
    ZhActive() {
      return this.$route.params.locale === 'zh';
    },
  },
};
</script>

<style lang="scss" scoped>
.language-button-wrapper {
  .language-button__item {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.1px;
    text-decoration: none;
    transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1);
    white-space: nowrap;

    @media screen and (max-width: 415px) {
      font-weight: 400;
    }
  }

  &.white {
    color: #fff;
    .language-button__item {
      color: #fff;
      &:hover {
        color: rgba(164, 166, 181, 1);
        transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      &.active {
        color: #0a7cff;
      }
    }
  }
  &.black {
    color: rgba(34, 46, 55, 1);
    .language-button__item {
      color: rgba(34, 46, 55, 1);
      &:hover {
        color: rgba(164, 166, 181, 1);
        transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      &.active {
        color: #0a7cff;
      }
    }
  }
}
</style>
